import React from 'react';
import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const overlay = {
    backgroundColor: 'rgba(0,0,0,0.85)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,
};
const Modalcontent = {
    borderRadius: '4px',
    // backgroundColor: '#F4F8FE',
    backgroundColor: 'white',
    position: 'absolute',
    width: '917px',
    // height: '427px',
    overflow: 'auto',
};

export const AcceptErrorModal = (props) => {
    useEffect(() => {}, []);
    return (
        <>
            {props.showModal ? (
                <div
                    id="overlay"
                    style={overlay}
                    onClick={() => {
                        props.setShowModal(false);
                    }}
                >
                    <div
                        id="modalContent"
                        style={Modalcontent}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Box component="main" sx={{ mt: 3, ml: 3 }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                更新に失敗しました。
                                <br />
                                更新内容を確認し、再度「変更を保存」ボタンを押してください。
                                <br />
                                ※このメッセージが繰り返し出る場合は、お手数ですが時間をおいてからの実施をお願いします。
                            </Typography>
                            <Box sx={{ mt: 2 }}></Box>
                            <Stack my={2} mr={2} direction="row" justifyContent="end" spacing={1}>
                                <Button
                                    variant="contained"
                                    sx={{ width: 176 }}
                                    onClick={() => {
                                        props.setShowModal(false);
                                    }}
                                >
                                    閉じる
                                </Button>
                            </Stack>
                        </Box>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
